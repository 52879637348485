<template>
  <div class="v-fashionstar">
    <div class="top-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <img
              class="hide-mobile"
              src="@/assets/images/fashionstar/info-banner.png"
              alt=""
            />
            <img
              class="hide-desktop"
              src="@/assets/images/fashionstar/title-1.png"
              alt=""
            />
          </div>
          <div class="col-lg-6">
            <div class="videoWrapper">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/BNPDJ__TbOc?controls=0&vq=hd720&autoplay=1&loop=1&playlist=BNPDJ__TbOc&mute=1&modestbranding=1"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <img
              class="hide-desktop"
              src="@/assets/images/fashionstar/title-2.png"
              alt=""
            />
          </div>
        </div>
        <div class="ins-number">
          <div class="row">
            <div class="col-6 wow animate__animated animate__flipInX">
              <img src="@/assets/images/fashionstar/icon-ins.png" alt="" />
              <h4>#vfst #vfashionstar</h4>
              <p>Model audition 2021</p>
            </div>
            <div class="col-6">
              <p>Số người tham gia</p>
              <h2>
                <div id="numberWang">200</div>
                <span>+</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="scroll-animate">
        <div class="relative">
          <img
            src="@/assets/images/fashionstar/icon-scroll.png"
            class="scroll-img"
          />
          <span>Xem thêm</span>
        </div>
      </div>
    </div>
    <div class="star-of-month">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="star-of-month__left">
              <h3 class="wow animate__animated animate__flipInX">
                Vfashionstar<br />
                đã tìm ra ngôi sao thời trang<br />
                tháng 4
              </h3>
              <div class="star-of-month__right hide-desktop">
                <div class="relative">
                  <img
                    class="img-star-bg"
                    src="@/assets/images/fashionstar/img-star-bg.png"
                    alt=""
                  />
                  <img
                    class="img-star-user wow animate__animated animate__flipInY animate__delay-1s"
                    src="@/assets/images/fashionstar/img-star-user-t04.jpg"
                    alt=""
                  />
                  <h2 class="wow animate__animated animate__tada">
                    APRIL <span>4</span>
                  </h2>
                </div>
              </div>
              <div class="wow animate__animated animate__fadeInUp">
                <p>
                  <b>Vfashionstar đã tìm ra ngôi sao thời trang tháng 4.</b>
                  Tuấn Lê chính là người đạt giải thưởng lần này. Bạn ấy sẽ đồng
                  hành cùng Vfashionstar thể hiện phong cách thời trang riêng
                  của bản thân.
                </p>
                <p>
                  Hãy cùng chờ đợi Tuấn Lê thể hiện vẻ ngoài thời thượng với tư
                  cách là người mẫu các bạn nhé! (Hình ảnh sẽ được cập nhật vào
                  ngày ra mắt lookbook).
                </p>
                <p>
                  Thử thách #vfst vẫn đang tiếp tục!!! Không chỉ có một ngôi sao
                  thời trang, tất cả các bạn đều có thể.
                </p>
                <p>
                  <b
                    >Hãy đính kèm #vfst trên Instagram và tham gia thật nhiều
                    nào các bạn ơi!</b
                  >
                </p>
                <a href="https://limeorange.vn/vstar/032021" title=""
                  >XEM CHI TIẾT</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6 hide-mobile">
            <div class="star-of-month__right">
              <div class="relative">
                <img
                  class="img-star-bg"
                  src="@/assets/images/fashionstar/img-star-bg.png"
                  alt=""
                />
                <img
                  class="img-star-user wow animate__animated animate__flipInY animate__delay-1s"
                  src="@/assets/images/fashionstar/img-star-user-t04.jpg"
                  alt=""
                />
                <h2 class="wow animate__animated animate__tada">
                  APRIL <span>4</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-photos">
      <div class="container">
        <h3 class="list-photos-title">
          DANH SÁCH SỐ NGƯỜI THAM GIA cuộc thi
          <a
            href="https://www.instagram.com/explore/tags/vfashionstar/"
            target="_blank"
            title=""
          >
            <span class="material-icons"> add </span>
          </a>
        </h3>
        <div
          class="row row-cols-2 row-cols-md-3 gallery-wrapper clearfix"
          ref="scrollComponent"
        >
          <!-- <post-component v-for='post in dataList' :post="post" :key="post.id" /> -->
          <v-fashion
            v-for="(item, i) in dataList"
            :key="item.id"
            :index="i"
            :item="item"
          />
        </div>
      </div>
      <div v-if="isLoading" class="text-center">
        <i
          class="fa fa-spinner fa-pulse fa-2x fa-fw"
          style="color: #e44b81"
        ></i>
      </div>
    </div>
    <div class="bottom-content">
      <div class="container">
        <img
          class="w-100 hide-mobile"
          src="@/assets/images/fashionstar/bg-line.jpg"
          alt=""
        />
        <img
          class="w-100 hide-desktop"
          src="@/assets/images/fashionstar/bg-line-mb.jpg"
          alt=""
        />
        <h3>LIMEORANGE MODEL AUDITION</h3>
        <p>
          Bạn có ước mơ làm người mẫu? Bạn đang muốn một nơi để thể hiện bản
          thân? Lime Orange sẽ giúp bạn!
        </p>
        <p>
          Đây là chương trình tìm kiếm người mẫu cho dự án trang phục LimeOrange
          2021.
        </p>
        <p>
          Chương trình là cơ hội tỏa sáng cho cả những người mẫu chuyên nghiệp
          và các bạn trẻ chưa có kinh nghiệm.
        </p>
        <p>
          Nếu được chọn bạn sẽ được làm việc trong môi trường chuyên nghiệp và
          có cơ hội phát triển cùng LimeOrange.
        </p>
        <h4>Thể lệ tham gia:</h4>
        <p>* Độ tuổi: 18~ 26 tuổi</p>
        <p>* Giới tính: Nam - Nữ</p>
        <p>* Nơi ở: TP Hồ Chí Minh</p>
        <h4>Cách thức tham gia:</h4>
        <p>
          Chọn hình thể hiện phong cách cá nhân của bạn kèm hashtag
          #vfashionstar và tải lên instagram cá nhân.
        </p>
        <h4>Lợi ích hoạt động:</h4>
        <p>1- Trở thành người mẫu cho các sản phẩm của LimeOrange</p>
        <p>2- Được trả phí tương đương với người mẫu chuyên nghiệp</p>
        <p>3- Được hỗ trợ trang phục.</p>
        <p>
          Thời gian hoạt động diễn ra từ tháng 03.2021 ~ 12.2021. Khi tham gia
          bạn sẽ có cơ hội được tuyển dụng bất kì lúc nào.
        </p>
        <p>
          Link:
          <a
            href="https://www.instagram.com/explore/tags/vfashionstar/"
            target="_blank"
            >https://www.instagram.com/explore/tags/vfashionstar/</a
          >
        </p>
        <p>Hotline: 1900 636 820 <b>(thứ 2- thứ 6)</b></p>
      </div>
    </div>
    <footer>
      <div class="container">
        <div class="footer-inner">
            <div class="footer-left">
                <img src="@/assets/images/fashionstar/icon-star.png" alt="">
                <div>
                    <h3>FASHION STAR JOINT STOCK COMPANY</h3>
                    <p>Địa chỉ: Lô E19 - 20, đường 12, khu công nghiệp Lê Minh Xuân, huyện Bình Chánh,</p>
                    <p>Thành phố Hồ Chí Minh, thành phố Hồ Chí Minh 700900, Việt Nam</p>
                    <p>[Email]: andy@fashionstar.vn</p>
                    <p>[Phone]: 028 3925 5050</p>
                    <p>[Website]: https://fashionstar.vn/</p>
                    <p>Mã số thuế: 0304791321</p>
                </div>
            </div>
            <div class="footer-right">
                <h3>[ Hotline ] : 1900 636 820</h3>
                <ul class="list-social">
                    <li>
                        <a href="https://www.instagram.com/limeorange.vn/" title="">
                            <img src="@/assets/images/fashionstar/instagram_icn.png" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/limeorange2006" title="">
                            <img src="@/assets/images/fashionstar/facebook_icn.png" alt="">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UC0PYOGg_0uGAubWWQwXz0qg" title="">
                            <img src="@/assets/images/fashionstar/youtube_icn.png" alt="">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  onBeforeMount,
} from "vue";
import VFashion from "@/components/VFashion.vue";
export default defineComponent({
  name: "Home",
  components: { VFashion },

  setup() {
    const dataList = ref([]);
    const scrollComponent = ref(null);
    const dataToken = ref();
    const isLoading = ref(false);
    const currentPage = ref(0);
    const isInitialRequestLoading = ref(true);
    const params = {
      limit: 15,
      next_page: "",
    };

    // const fetchData = (nextPage) => {
    //    if(nextPage){
    //        params.next_page = nextPage
    //    }
    //    axios.post('membership/instagram/get_hashtag_contents', params).then(response => {
    //        dataList.value = response.data.data.list_medias,
    //        dataToken.value = response.data.data.next_page_token
    //     }).catch(error => {
    //             console.log(error)
    //     }).finally(() => loading.value = false)
    //   }

    const fetchData = async () => {
      try {
        if (currentPage.value) {
          params.next_page = currentPage.value;
        }
        // const res = await axios.get('https://jsonplaceholder.typicode.com/posts', params)
        const res = await axios.post("instagram/get_hashtag_contents", params);
        const { data } = res.data;
        if (data) {
          currentPage.value = data?.next_page_token;
          const mergedArray = [...dataList.value, ...data.list_medias];
          let set = new Set();
          let uniqueItems = mergedArray.filter((item) => {
            if (!set.has(item.id)) {
              set.add(item.id);
              return true;
            }
            return false;
          }, set);
          dataList.value = uniqueItems;
          // logigc old
          // dataList.value = dataList.value.concat(
          //   data.list_medias.filter((item) => dataList.value.indexOf(item) < 0)
          // );
        }
      } catch (err) {
        console.log(err); // state-of-the-art-error-handling
      }
    };

    const handleScroll = async () => {
      let element = scrollComponent.value;
      if (isLoading.value || !currentPage.value) {
        return;
      }

      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        isLoading.value = true;
        await fetchData();
        isLoading.value = false;
      }
    };

    onBeforeMount(async () => {
      await fetchData();
      isInitialRequestLoading.value = false;
    });
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      params,
      fetchData,
      dataList,
      dataToken,
      scrollComponent,
      isInitialRequestLoading,
      isLoading,
    };
  },
});
</script>
