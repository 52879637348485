import { createApp } from "vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import * as Config from "@/constants";
import "@/assets/plugins/font-awesome/css/font-awesome.min.css";
import "@/assets/scss/main.scss";
axios.defaults.baseURL = Config.API_URL_GLOBAL; // the prefix of the URL
axios.defaults.headers.get["Accept"] = "application/json"; // default header for all get request
axios.defaults.headers.post["Accept"] = "application/json"; // default header for all POST request
createApp(App).use(router).mount("#app");
