<template>
  <div class="col list_item_collection grid-item" v-if="!item.items">
    <a
      class="list-photos-inner wow animate__animated animate__fadeInDown"
      v-if="item.media_type == 'IMAGE'"
      :href="item.permalink"
      target="_blank"
    >
      <div class="list-photos-img">
        <img :src="item.media_url" />
      </div>
      <div class="list-photos-info">
        <ul>
          <li>
            <i class="fa fa-heart" aria-hidden="true"></i>
            <span>{{ item.like_count }}</span>
          </li>
          <li>
            <i class="fa fa-comment" aria-hidden="true"></i>
            <span>{{ item.comments_count }}</span>
          </li>
        </ul>
      </div>
    </a>
    <a
      class="list-photos-inner wow animate__animated animate__fadeInDown"
      v-if="item.media_type == 'CAROUSEL_ALBUM' && item.items.length > 0"
      href="{{item.permalink}}"
      target="_blank"
    >
      <div class="list-photos-img">
        <img :src="item.items[0].media_url" />
      </div>
      <div class="list-photos-info">
        <ul>
          <li>
            <i class="fa fa-heart" aria-hidden="true"></i>
            <span>{{ item.like_count }}</span>
          </li>
          <li>
            <i class="fa fa-comment" aria-hidden="true"></i>
            <span>{{ item.comments_count }}</span>
          </li>
        </ul>
      </div>
    </a>
    <a
      class="list-photos-inner wow animate__animated animate__fadeInDown type-video"
      v-if="item.media_type == 'VIDEO'"
      :href="item.permalink"
      target="_blank"
    >
      <div class="list-photos-img">
        <img :src="item.thumbnail_url" />
      </div>
      <div class="list-photos-info">
        <ul>
          <li>
            <i class="fa fa-heart" aria-hidden="true"></i>
            <span>{{ item.like_count }}</span>
          </li>
          <li>
            <i class="fa fa-comment" aria-hidden="true"></i>
            <span>{{ item.comments_count }}</span>
          </li>
        </ul>
      </div>
    </a>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    item: Object,
  },
});
</script>
